.footer {
    position: relative;
    padding-top: 60px;
    padding-bottom: 35px;
    overflow: hidden;
}
.bgImg {
    width: 300px;
    height: 300px;
    left: 65%;
    top: 170px;
    transform: rotate(-70deg);
}
.copyright {
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    letter-spacing: -0.2px !important;
    color: #3E4462 !important;
}
.navLink {
    padding: 0 !important;
    padding-right: 40px !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.2px;
    color: #3E4462 !important;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}
.navLink:hover {
    color: #000000 !important;
}
.navbarBrand {
    margin-right: 45px !important;
}
.dividerRow {
    padding-top: 40px;
    padding-bottom: 40px;
}
.dark .copyright {
    color: rgba(255, 255, 255, 0.5) !important;
    mix-blend-mode: normal;
}
.dark .navbarBrand svg path {
    fill: #ffffff;
}
.dark .navLink:hover {
    color: rgba(255, 255, 255, 0.5) !important;
}
.socials {
    display: flex;
    flex-wrap: wrap;
}
.socials div{
    margin-right: 15px;
}
@media screen and (max-width: 991px) {
    .navbarNav {
        flex-direction: row !important;
    }
    .footer {
        padding-top: 40px;
        padding-bottom: 45px;
    }
    .bgImg {
        display: none;
    }
    .socials {
        justify-content: center;
        margin: auto;
        margin-top: 15px;
    }
}
@media screen and (max-width: 768px) {
    .navbar {
        flex-direction: column ;
    }
    .navbarNav {
        margin-top: 30px;
        flex-direction: column !important;
        text-align: center;
    }
    .navbarBrand {
        margin-right: 0 !important;
        padding: 0;
    }
    .navLink {
        padding-right: 0 !important;
        margin-bottom: 30px;
    }
    .socials {
        margin-top: unset;
    }
}
@media screen and (max-width: 450px) {
    .footer {
        padding-top: 30px;
        padding-bottom: 35px;
    }
}
@media screen and (max-width: 371px) {
    .socials > div:first-child {
        padding-bottom: 15px;
    }
}
@media screen and (max-width: 350px) {
    .gridWrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
    .copyright {
        font-size: 14px !important;
        line-height: 24px !important;
    }
}
