.form {
    margin-top: 35px;
}
.p15 {
    padding-bottom: 15px;
}
.p20 {
    padding-bottom: 20px;
}
button:disabled {
    background-color: #D4D4D4 !important;
}
button:disabled:hover {
    border: 2px solid transparent !important;
    cursor: unset;
}
.sent {
    color: green;
    font-weight: bold !important;
}
@media screen and (max-width: 450px) {
    .form a[class*="Button"],
    .form button[class*="Button"] {
        height: 50px !important;
        font-size: 18px !important;
    }
}




