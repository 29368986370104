.cta {
    height: 100%;
    position: relative;
    background: #FEFEE1;
    overflow: hidden;
}
.cta img {
    width: 100%;
    max-height: 700px;
    max-width: 350px;
}
.bgImg {
    height: 35%;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
}
.bgImgLeft {
    left: -100px;
}
.bgImgRight {
    right: -445px;
}
.rightColumn {
    padding-top: 80px;
}
.leftColumn {
    text-align: center;
}

@media screen and (max-width: 991px) {
    .cta {
        height: 100%;
    }
    .bgImg {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .cta h1 {
        max-width: 400px;
    }
    .cta {
        padding-bottom: 60px;
    }
    .rightColumn {
        padding-top: 60px;
    }
}
@media screen and (max-width: 450px) {
    .gridWrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
    .leftColumn img:nth-child(2) {
        right: -15px !important;
    }
    .cta h1 {
        max-width: 300px;
    }
}