.features {
  height: 100%;
  position: relative;
  overflow: hidden;
}
.bgImg {
  width: 300px;
  height: 300px;
  right: -180px;
  bottom: -135px;
  transform: rotate(-70.23deg);
}
.feature {
  padding-right: 90px;
  margin-bottom: 50px;
}
.contentRow {
  margin-top: 70px;
}
.imgScreen {
  max-height: 650px;
  max-width: 300px;
  position: relative;
  top: -20px;
}

@media screen and (max-width: 1199px) {
  .feature {
    padding-right: 50px;
  }
}

@media screen and (max-width: 991px) {
  .features {
      height: 100%;
  }
  .leftCol {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bgImg {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .feature {
    padding-right: 0;
  }
  .contentRow {
    margin-top: 60px;
  }
}

@media screen and (max-width: 575px) {
  .contentRow {
    margin-top: 40px;
  }
  .feature {
    padding-right: 20px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 450px) {
  .gridWrapper {
      padding-left: 15px;
      padding-right: 15px;
  }
}

