.cta h1 {
    padding-bottom: 70px !important;
    max-width: 420px;
}
.description {
    padding-top: 16px;
    max-width: 420px;
}
.middleLevel {
    padding-top: 0;
}
@media screen and (max-width: 991px) {
    .cta h1 {
        padding-bottom: 30px !important;
        margin: 0 auto !important;
    }
    .description {
        margin: 0 auto !important;
    }
}
@media screen and (max-width: 450px) {
    .col {
        margin-bottom: 10px;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .buttonRow {
        display:grid;
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        row-gap: 15px;
    }
    .description {
        max-width: 310px;
    }
    .buttonRow {
        padding-top: 30px;
    }
}
